<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mx-2"
        outlined
        color="primary"
        small
        rounded
      >
        <v-icon>mdi-plus</v-icon> AGREGAR ACTIVIDAD DE {{ title }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title background-color="green lighten-3">
        <span class="text-h5">AGREGAR ACTIVIDAD DE {{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <span class="green--text">Fecha</span>
              <v-menu
                v-model="datepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :error-messages="validationErrors.date"
                    v-model="newTracking.date"
                    v-on="on"
                    solo
                    dense
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="es-co"
                  v-model="newTracking.date"
                  @input="datepicker = false"
                  solo
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <span class="green--text">Descripción</span>
              <v-textarea
                solo
                v-model="newTracking.description"
                :error-messages="validationErrors.description"
                solid
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <evidences
                ref="evidencesComponent"
                content-type="AffectedTracking"
                :object-id="trackingId"
                module="human_rights"
              ></evidences>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="save()"> Agregar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Evidences from "@/components/commonComponents/Evidences";
import Swal from "sweetalert2";
import { storeAffectedTracking } from "@/services/humansRights";

export default {
  components: { Evidences },
  props: ["action_route_id", "affected_id", "title"],
  data() {
    return {
      datepicker: false,
      dialog: false,
      trackingId: "",
      newTracking: {
        action_route_id: "",
        affected_id: "",
        date: "",
        description: "",
      },
    };
  },
  validations() {
    return {
      newTracking: {
        date: { required },
        description: { required },
      },
    };
  },
  methods: {
    async save() {
      this.newTracking.affected_id = this.affected_id;
      this.newTracking.action_route_id = this.action_route_id;
      this.$v.newTracking.$touch();
      if (this.$v.newTracking.$invalid) return;
      try {
        const response = await storeAffectedTracking(this.newTracking);
        this.trackingId = response.data.affected_tracking_id;
        await this.$nextTick();
        await this.$refs.evidencesComponent.upload();

        this.newTracking = {
          action_route_id: "",
          affected_id: "",
          date: "",
          description: "",
        };
        this.dialog = false;
        this.$emit("saved");
        Swal.fire(
          "Seguimiento guardado correctamente",
          "",
          "success"
        );
      } catch (e) {
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
        console.error(e);
      }
      this.dialog = false;
    },
  },
  computed: {
    validationErrors() {
      return {
        date: [
          ...[
            !this.$v.newTracking.date.required ? "Este campo es requerido" : "",
          ].filter((x) => x !== ""),
        ],
        description: [
          ...[
            !this.$v.newTracking.description.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
        ],
      };
    },
  },
};
</script>
