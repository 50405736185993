<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title background-color="green lighten-3">
        <span class="text-h5">DETALLE</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <span class="green--text">Fecha</span>
              <v-text-field
                readonly
                v-model="tracking.date"
                solo
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <span class="green--text">Descripción</span>
              <v-textarea
                readonly
                solo
                v-model="tracking.description"
                solid
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <evidence-item
                v-for="(evidence, i) in tracking.evidences"
                :key="`evidence_${i}`"
                :evidenceItem="evidence"
              >
              </evidence-item>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components: { EvidenceItem },
  props: ["trackingId"],
  data() {
    return {
      datepicker: false,
      dialog: false,
      tracking: {
        date: "",
        description: "",
        evidences: [],
      },
    };
  },
  methods: {
    show(item) {
      this.tracking.date = item.date;
      this.tracking.description = item.description;
      this.tracking.evidences = item.evidences;
      this.dialog = true;
    },
  },
};
</script>
