const questionTypes = {
  TEXT: "TEXT",
  OPTIONS: "OPTIONS",
  MULTI: "MULTI",
  NUMBER: "NUMBER",
  DATE: "DATE",
};

Object.freeze(questionTypes);

export default questionTypes;
