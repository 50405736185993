<template>
  <main>
    <span class="green--text">{{ label }}</span>
    <v-autocomplete
      v-model="selected"
      :required="required"
      multiple
      chips
      :items="options"
      item-text="label"
      return-object
      @change="onValueChange"
    ></v-autocomplete>
  </main>
</template>
<script>
export default {
  props: {
    label: String,
    required: Boolean,
    options: Array,
    value: Array,
  },
  data() {
    return {
      selected: this.value,
    };
  },
  methods: {
    onValueChange() {
      this.$emit("input", this.selected);
    },
  },
};
</script>
