<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA DE EVENTOS
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon> Detalle del evento
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-tabs vertical>
        <v-tab>
          Información General
          <v-icon right> mdi-chevron-right </v-icon>
        </v-tab>
        <v-tab v-if="mode === 'edit'">
          Estado del evento
          <v-icon right> mdi-chevron-right </v-icon>
        </v-tab>
        <v-tab
          v-for="(affected, i) in womanCase.affecteds"
          :key="`${i}_affected`"
        >
          {{ affected.name }} {{ affected.lastname }}
          <v-icon right>mdi-chevron-right</v-icon>
        </v-tab>
        <v-tab-item>
          <v-card flat outlined>
            <create-case
              :isFemale="true"
              ref="eventForm"
              :mode="mode"
              @updatedEvent="showEvent()"
            ></create-case>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="mode === 'edit'">
          <v-card flat outlined>
            <state-management :eventId="womanCase.id"></state-management>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-for="(affected, i) in womanCase.affecteds"
          :key="`${i}_affected_form`"
        >
          <v-card flat outlined>
            <custom-form
              code="WOMEN"
              :affectedId="affected.affected_id"
              :affected="affected"
              :caseInfo="caseMaped"
              :formResponse="affected.formResponse"
            ></custom-form>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
import {eventTypesList, showEvent} from "@/services/humansRights";
import CreateCase from "../HumansRights/case/create.vue";
import StateManagement from "../HumansRights/StateManagement.vue";
import CustomForm from "./WomensForm/Form.vue";
import {mapState} from "vuex";
export default {
  components: { CreateCase, StateManagement, CustomForm },
  data() {
    return {
      mode: "create",
      womanCase: {
        id: "",
        event_type_id: "",
        event_subtype_id: "",
        sidewalk_id: "",
        date: "",
        time: "",
        description: "",
        department_id: "",
        zone_id: "",
        municipality_id: "",
        territory_type_id: "",
        territory_id: "",
        is_female: true,
        contact_information: {
          name: "",
          lastname: "",
          phone_number: "",
          email: "",
        },
        affecteds: [],
        currentState: {},
        requiredData: [],
        evidences: [],
      },
      disharmonyTypes : [],
    };
  },
  async created() {
    const mode = this.$route.params.mode;
    this.mode = mode;

    const eventTypesResponse = await eventTypesList();
    this.disharmonyTypes = eventTypesResponse.data;

    if (mode == "edit") {
      const id = this.$route.params.id;
      try {
        this.showLoader();
        const eventShowResponse = await showEvent(id);
        const newCase = {
          id: eventShowResponse.data.id,
          disharmony_type_id: eventShowResponse.data.event_type.disharmony,
          event_type_id: eventShowResponse.data.event_type.id,
          event_subtype_id: eventShowResponse.data.event_subtype.id,
          sidewalk_id: eventShowResponse.data.sidewalk?.id || null,
          date: eventShowResponse.data.date,
          time: eventShowResponse.data.time,
          description: eventShowResponse.data.description,
          department_id: eventShowResponse.data.department.id,
          zone_id: eventShowResponse.data.zone?.id || null,
          municipality_id: eventShowResponse.data.municipality.id,
          territory_type_id:
            eventShowResponse.data.territory?.tipo_territorio.id || null,
          territory_id: eventShowResponse.data.territory?.id || null,
          is_female: eventShowResponse.data.is_female,
          contact_information: {
            name: eventShowResponse.data.contact_information?.name || "",
            lastname:
              eventShowResponse.data.contact_information?.lastname || "",
            phone_number:
              eventShowResponse.data.contact_information?.phone_number || "",
            email: eventShowResponse.data.contact_information?.email || "",
          },
          affecteds: eventShowResponse.data.affected.map((x) => {
            return {
              affected_id: x.id,
              id: x.people.id,
              name: x.people.name,
              second_name : x.people?.second_name || '',
              lastname: x.people.lastname,
              second_surname : x.people?.second_surname || '',
              documentNumber: x.people.document_number,
              genre: x.people.genre,
              age: x.people.age,
              territory: {
                territorio_id: x.people.territory.id,
                descripcion: x.people.territory.descripcion,
              },
              sidewalk: {
                pueblo_id: x.people.indigenous_group,
                descripcion: this.dataSource.data.Pueblo.find(p => p.pueblo_id === x.people.indigenous_group)?.descripcion || '',
              },
              formResponse: x.form_response,
            };
          }),
          currentState: eventShowResponse.data.current_state,
          evidences: eventShowResponse.data.evidences,
          event_subtype_values: eventShowResponse.data.event_subtype_values,
        };

        this.$nextTick(() => {
          this.womanCase = newCase;
          this.$refs.eventForm.setEventData(newCase);
        });
        this.hideLoader();
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    }
  },
  methods: {
    async showEvent() {
      const id = this.$route.params.id;
      this.eventId = id;
      try {
        this.showLoader();
        const eventShowResponse = await showEvent(id);
        const newCase = {
          id: eventShowResponse.data.id,
          disharmony_type_id: eventShowResponse.data.event_type.disharmony,
          event_type_id: eventShowResponse.data.event_type.id,
          event_subtype_id: eventShowResponse.data.event_subtype.id,
          sidewalk_id: eventShowResponse.data.sidewalk.id,
          date: eventShowResponse.data.date,
          time: eventShowResponse.data.time,
          description: eventShowResponse.data.description,
          department_id: eventShowResponse.data.department.id,
          zone_id: eventShowResponse.data.zone.id,
          municipality_id: eventShowResponse.data.municipality.id,
          territory_type_id:
            eventShowResponse.data.territory.tipo_territorio.id,
          territory_id: eventShowResponse.data.territory.id,
          is_female: eventShowResponse.data.is_female,
          contact_information: {
            name: eventShowResponse.data.contact_information?.name || "",
            lastname:
              eventShowResponse.data.contact_information?.lastname || "",
            phone_number:
              eventShowResponse.data.contact_information?.phone_number || "",
            email: eventShowResponse.data.contact_information?.email || "",
          },
          affecteds: eventShowResponse.data.affected.map((x) => {
            return {
              affected_id: x.id,
              id: x.people.id,
              name: x.people.name,
              second_name : x.people?.second_name || '',
              lastname: x.people.lastname,
              second_surname : x.people?.second_surname || '',
              documentNumber: x.people.document_number,
              genre: x.people.genre,
              age: x.people.age,
              territory: {
                territorio_id: x.people.territory.id,
                descripcion: x.people.territory.descripcion,
              },
              sidewalk: {
                pueblo_id: x.people.indigenous_group,
                descripcion: this.dataSource.data.Pueblo.find(p => p.pueblo_id === x.people.indigenous_group)?.descripcion || '',
              },
              formResponse: x.form_response,
            };
          }),
          currentState: eventShowResponse.data.current_state,
          evidences: eventShowResponse.data.evidences,
          event_subtype_values: eventShowResponse.data.event_subtype_values,
        };

        this.$nextTick(() => {
          this.womanCase = newCase;
          this.$refs.eventForm.setEventData(newCase);
        });
        this.hideLoader();
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  computed : {
    ...mapState(['dataSource']),
    eventTypes() {
      return (
          this.disharmonyTypes.find(
              (x) => x.id === this.womanCase.disharmony_type_id
          )?.event_types || []
      );
    },
    eventSubtypes() {
      return (
          this.eventTypes.find((x) => x.id === this.womanCase.event_type_id)
              ?.subtypes || []
      );
    },
    caseMaped(){
      return {
        id : this.womanCase.id,
        disharmonyType : this.disharmonyTypes.find(x => x.id === this.womanCase.disharmony_type_id).name,
        eventType : this.eventTypes.find(x => x.id === this.womanCase.event_type_id).name,
        eventSubtype : this.eventSubtypes.find(x => x.id === this.womanCase.event_subtype_id).name,
        date : this.womanCase.date,
        time : this.womanCase.time,
        description : this.womanCase.description,
        department : this.dataSource.data.Departamento.find(x => x.departamento_id === this.womanCase.department_id).descripcion,
        zone : this.dataSource.data.Zona.find(x => x.zona_id === this.womanCase.zone_id)?.descripcion || '',
        municipality : this.dataSource.data.Municipio.find(x => x.municipio_id === this.womanCase.municipality_id)?.descripcion || '',
        territoryType : this.dataSource.data.TipoTerritorio.find(x => x.id === this.womanCase.territory_type_id)?.nombre || '',
        territory : this.dataSource.data.Territorio.find(x => x.territorio_id === this.womanCase.territory_id)?.descripcion || '',
        sidewalk : this.dataSource.data.Vereda.find(x => x.vereda_id === this.womanCase.sidewalk_id)?.descripcion || '',
        is_female: this.womanCase.is_female,
        affectedInfo : this.womanCase.affecteds.map(x => {
          return {
            name : `${x.name} ${x.second_name || ''}`,
            lastname : `${x.lastname} ${x.second_surname || ''}`,
            documentNumber : x.documentNumber,
            genre : x.genre,
            age : x.age,
            territory : x.territory.descripcion,
            sidewalk: x.sidewalk.descripcion,
          }
        }),
      }
    }
  }
};
</script>
