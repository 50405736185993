<template>
  <main>
    <span class="green--text">{{ label }}</span>
    <v-text-field
      :required="required"
      v-model="val"
      @input="onValueChange"
    ></v-text-field>
  </main>
</template>
<script>
export default {
  props: {
    label: String,
    required: Boolean,
    value: String,
  },
  data() {
    return {
      val: this.value,
    };
  },
  methods: {
    onValueChange() {
      this.$emit("input", this.val);
    },
  },
};
</script>
