<template>
  <v-container>
    <v-btn color="primary" rounded class="mb-2"
    @click="womenFormDocumentExport()"
    >
      <!-- icon document-->
      <v-icon>mdi-file-document</v-icon>
      Exportar
    </v-btn>
    <section-form
      v-for="(section, i) in form.sections"
      :key="`section_${i}`"
      :title="section.name"
    >
      <v-row>
        <v-col
          v-for="(question, i) in section.questions"
          :key="`question_${i}`"
          cols="12"
          lg="4"
          md="4"
          sm="12"
        >
          <component
            :is="getFormComponent(question.type)"
            v-model="question.selected"
            v-bind="question"
          >
          </component>
        </v-col>
      </v-row>
    </section-form>
    <section-form title="DESCRIPCIÓN DE LOS HECHOS">
      <v-textarea v-model="description" :error-messages="validationErrors.description" dense solo></v-textarea>
    </section-form>
    <section-form title="EVIDENCIAS">
      <evidences
        ref="affectedPeopleEvidence"
        :objectId="this.affectedId"
        contentType="EventAffectedPeople"
        module="human_rights"
      ></evidences>
    </section-form>
    <section-form title="OBSERVACIONES FINALES">
      <v-textarea v-model="finalObservations" :error-messages="validationErrors.finalObservations" dense solo></v-textarea>
    </section-form>
    <div class="text-center mt-2 mb-2">
      <v-btn class="mx-2" color="primary" rounded small @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
    <section-form title="RUTAS DE ATENCION">
      <affected-woman-tracking
        :affected-id="affectedId"
      ></affected-woman-tracking>
    </section-form>
  </v-container>
</template>
<script>
import SectionForm from "./SectionForm.vue";
import Select from "./Select.vue";
import MultiSelect from "./MultiSelect.vue";
import InputText from "./InputText.vue";
import InputNumber from "./InputNumber.vue";
import InputDate from "@/views/WomenRights/WomensForm/InputDate";
import {required} from "vuelidate/lib/validators";
import {
  getForm,
  storeFormResponse,
  updateFormResponse,
} from "@/services/humansRights";
import Swal from "sweetalert2";
import questionTypes from "../../../enums/questionTypes";
import { isEmpty } from "lodash";
import Evidences from "../../../components/commonComponents/Evidences.vue";
import AffectedWomanTracking from "@/views/WomenRights/AffectedWomanTracking";
import womenFormDocument from "@/utils/womensFormDocument";
export default {
  props: {
    code: String,
    affectedId: Number,
    formResponse: Object,
    caseInfo: Object,
    affected : Object
  },
  components: {
    AffectedWomanTracking,
    SectionForm,
    Select,
    InputText,
    InputNumber,
    MultiSelect,
    Evidences,
    InputDate,
  },
  data() {
    return {
      description: "",
      finalObservations: "",
      form: {},
    };
  },
  validations(){
    return {
      description : {required},
      finalObservations : {required}
    }
  },
  methods: {
    getFormComponent(type) {
      return {
        [questionTypes.TEXT]: "InputText",
        [questionTypes.OPTIONS]: "Select",
        [questionTypes.NUMBER]: "InputNumber",
        [questionTypes.MULTI]: "MultiSelect",
        [questionTypes.DATE]: "InputDate",
      }[type];
    },
    getDefaultValue(type) {
      return {
        [questionTypes.TEXT]: "",
        [questionTypes.OPTIONS]: {
          id: "",
          label: "",
          value: "",
          required_alternative: false,
          alternative_value: "",
        },
        [questionTypes.NUMBER]: "",
        [questionTypes.MULTI]: [],
        [questionTypes.DATE]: "",
      }[type];
    },
    hasQuestionValue(type, question) {
      if (questionTypes.TEXT === type) {
        if (question.selected === "") {
          return [];
        } else {
          return [
            {
              question_id: question.id,
              option_id: null,
              value: question.selected,
              alternative_value: null,
            },
          ];
        }
      }
      if (questionTypes.DATE === type) {
        if (question.selected === "") {
          return [];
        } else {
          return [
            {
              question_id: question.id,
              option_id: null,
              value: question.selected,
              alternative_value: null,
            },
          ];
        }
      }

      if (questionTypes.OPTIONS === type) {
        if (question.selected.value === "") {
          return [];
        } else {
          return [
            {
              question_id: question.id,
              option_id: question.selected.id,
              value: question.selected.value,
              alternative_value: question.selected.alternative_value || null,
            },
          ];
        }
      }

      if (questionTypes.NUMBER === type) {
        if (question.selected === "") {
          return [];
        } else {
          return [
            {
              question_id: question.id,
              option_id: null,
              value: question.selected,
              alternative_value: null,
            },
          ];
        }
      }
      if (questionTypes.MULTI === type) {
        if (question.selected.length === 0) {
          return [];
        } else {
          return question.selected.map((selected) => {
            return {
              question_id: question.id,
              option_id: selected.id,
              value: selected.value,
              alternative_value: null,
            };
          });
        }
      }
    },
    getQuestionValue(type, question) {
      if (questionTypes.TEXT === type) {
        return [
          {
            question_id: question.id,
            option_id: null,
            value: question.selected,
            alternative_value: null,
          },
        ];
      }
      if (questionTypes.DATE === type) {
        return [
          {
            question_id: question.id,
            option_id: null,
            value: question.selected,
            alternative_value: null,
          },
        ];
      }

      if (questionTypes.OPTIONS === type) {
        return [
          {
            question_id: question.id,
            option_id: question.selected.id,
            value: question.selected.value,
            alternative_value: question.selected.alternative_value || null,
          },
        ];
      }
      if (questionTypes.NUMBER === type) {
        return [
          {
            question_id: question.id,
            option_id: null,
            value: question.selected,
            alternative_value: null,
          },
        ];
      }
      if (questionTypes.MULTI === type) {
        return question.selected.map((selected) => {
          return {
            question_id: question.id,
            option_id: selected.id,
            value: selected.value,
            alternative_value: null,
          };
        });
      }
    },
    womenFormDocumentExport(){
      womenFormDocument(this.caseInfo, this.form, this.affected)
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.showLoader();
        let questionAnswers = [];
        this.form.sections.forEach((x) => {
          x.questions.forEach((question) => {
            if (this.hasQuestionValue(question.type, question).length) {
              questionAnswers.push(
                ...this.getQuestionValue(question.type, question)
              );
            }
          });
        });

        const response = {
          affected_id: this.affectedId,
          questionnaire_id: this.form.id,
          description: this.description,
          final_observations: this.finalObservations,
          answers: questionAnswers,
        };

        isEmpty(this.formResponse)
          ? await storeFormResponse(response)
          : await updateFormResponse(this.formResponse.id, response);
        await this.$refs.affectedPeopleEvidence.upload();
        this.hideLoader();
        Swal.fire("Registro guardado correctamente", "", "success");
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  async created() {
    try {
      this.showLoader();
      const formData = (await getForm(this.code))?.data;

      this.form = {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        sections: formData.sections.map((section) => {
          return {
            name: section.name,
            step: section.step,
            questions: section.questions.map((question) => {
              let selected = this.getDefaultValue(question.type);

              const mapMultiple = (answers, question_id) => {
                const question_answers = answers.filter(
                  (ans) => ans.question === question_id
                );
                if (question_answers.length) {
                  return question_answers.map((answer) => {
                    return {
                      id: answer.option,
                      label: answer.value,
                      value: answer.value,
                      required_alternative: answer.required_alternative,
                    };
                  });
                }
                return [];
              };

              const mapOptions = (answers, question_id) => {
                const answer =
                  answers.find((ans) => ans.question === question_id) || {};
                if (!isEmpty(answer)) {
                  return {
                    id: answer.option,
                    label: answer.value,
                    value: answer.value,
                    required_alternative: answer.required_alternative,
                  };
                }
              };

              const mapSingle = (answers, question_id) =>
                answers.find((ans) => ans.question === question_id)?.value ||
                "";

              if (!isEmpty(this.formResponse)) {
                if (this.formResponse.answers.length) {
                  if (
                    this.formResponse.answers.find(
                      (ans) => ans.question === question.id
                    )
                  ) {
                    selected = {
                      [questionTypes.MULTI]: mapMultiple(
                        this.formResponse.answers || [],
                        question.id
                      ),
                      [questionTypes.OPTIONS]: mapOptions(
                        this.formResponse.answers || [],
                        question.id
                      ),
                      [questionTypes.TEXT]: mapSingle(
                        this.formResponse.answers || [],
                        question.id
                      ),
                      [questionTypes.NUMBER]: mapSingle(
                        this.formResponse.answers || [],
                        question.id
                      ),
                      [questionTypes.DATE]: mapSingle(
                        this.formResponse.answers || [],
                        question.id
                      ),
                    }[question.type];
                  }
                }
              }

              return {
                ...question,
                selected: selected,
              };
            }),
          };
        }),
      };

      this.description = this.formResponse?.description || "";
      this.finalObservations = this.formResponse?.final_observations || "";
      await this.$refs.affectedPeopleEvidence.list();
      this.hideLoader();
    } catch (err) {
      console.error(err);
      this.hideLoader();
      Swal.fire("Error", "Ocurrio un error al procesar la solicitud", "error");
    }
  },
  computed : {
    validationErrors(){
      return {
        description: [...(!this.$v.description.required ? ["Este campo es requerido"] : [])],
        finalObservations: [...(!this.$v.finalObservations.required ? ["Este campo es requerido"] : [])],
      }
    }
  }
};
</script>
