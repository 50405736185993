<template>
  <main>
    <span class="green--text">{{ label }}</span>
    <v-menu
      v-model="datepicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <v-text-field v-model="val" v-on="on" solid dense> </v-text-field>
      </template>
      <v-date-picker
        locale="es-co"
        v-model="val"
        @input="datepicker = false"
        solo
      ></v-date-picker>
    </v-menu>
  </main>
</template>
<script>
export default {
  props: {
    label: String,
    required: Boolean,
    value: String,
  },
  data() {
    return {
      datepicker: "",
      val: this.value,
    };
  },
  methods: {
    onValueChange() {
      this.$emit("input", this.val);
    },
  },
  watch: {
    val() {
      this.onValueChange();
    },
  },
};
</script>
