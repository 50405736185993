<template>
  <div>
    <v-tabs fixed-tabs background-color="green lighten-3">
      <v-tab v-for="action in actionRoutes" :key="action.id">
        {{ action.name }}
      </v-tab>
      <v-tab-item
        v-for="action in actionRoutes"
        :key="`affected_tracking_${action.id}`"
      >
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
                    'items-per-page-options': [5,10,15]
            
          }"
          :headers="headers"
          :items="
            affectedTracking.filter((x) => x.action_route.id === action.id)
          "
          item-key="id"
        >
          <template v-slot:item.evidences="{ item }">
            <v-icon v-if="item.evidences.length" color="green"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title @click="show(item)">
                    <v-icon class="mr-2" small>mdi-eye</v-icon> Ver
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-row>
          <v-col cols="12" class="text-center">
            <affected-woman-tracking-store
              :affected_id="affectedId"
              :action_route_id="action.id"
              :title="action.name"
              :key="`affected_tracking_store_${action.id}`"
              @saved="list()"
            >
            </affected-woman-tracking-store>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
    <affected-woman-tracking-show
      ref="affectedTrackingShow"
      :tracking-id="selectedTrackingId"
    ></affected-woman-tracking-show>
  </div>
</template>

<script>
import { getActionRoutes, getAffectedTracking } from "@/services/humansRights";
import affectedWomanTrackingStore from "@/views/WomenRights/AffectedWomanTrackingStore";
import AffectedWomanTrackingShow from "@/views/WomenRights/AffectedWomanTrackingShow";
export default {
  props: ["affectedId"],
  name: "AffectedWomanTracking",
  async created() {
    this.actionRoutes = (await getActionRoutes())?.data;
    await this.list();
  },
  data() {
    return {
      actionRoutes: [],
      affectedTracking: [],
      selectedTrackingId: "",
      headers: [
        {
          text: "FECHA",
          align: "start",
          sortable: true,
          value: "date",
        },
        { text: "DESCRIPCION", value: "description" },
        { text: "EVIDENCIA", value: "evidences", align: "center" },
        { text: "ACCION", value: "action", align: "center" },
      ],
    };
  },
  methods: {
    async list() {
      this.affectedTracking = (
        await getAffectedTracking(this.affectedId)
      )?.data;
    },
    show(item) {
      this.selectedTrackingId = item.id;
      this.$refs.affectedTrackingShow.show(item);
    },
  },
  components: {
    AffectedWomanTrackingShow,
    affectedWomanTrackingStore,
  },
};
</script>

<style scoped></style>
