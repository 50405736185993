<template>
  <v-card>
    <v-toolbar flat color="white">
      <v-toolbar-title class="grey--text font-weight-bold">
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
