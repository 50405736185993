<template>
  <main>
    <span class="green--text">{{ label }}</span>
    <v-autocomplete
      v-model="selected"
      :required="required"
      :items="options"
      item-text="label"
      return-object
      @change="onValueChange"
    ></v-autocomplete>
    <v-text-field
      v-if="selected.required_alternative"
      v-model="selected.alternative_value"
      label="Otro (Cual)"
    ></v-text-field>
  </main>
</template>
<script>
export default {
  props: {
    label: String,
    required: Boolean,
    options: Array,
    value: Object,
  },
  data() {
    return {
      selected: this.value,
    };
  },
  methods: {
    onValueChange() {
      this.$emit("input", this.selected);
    },
  },
};
</script>
